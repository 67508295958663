import $ from "cash-dom";
import {getStoredQuery} from "@mono/node-starter/common/browser/url-query-storage.js";

export const round = (value, acc = 100000) => {
	return Math.round(value * acc) / acc;
};

export const cycle = (value, max = 1, min = 0) => {
	value = value - min;
	return (value < 0 ? ((max + (value % max)) % max) : value % max) + min;
};

export const clamp = (value, min, max) => {
	if (min > max) {
		[max, min] = [min, max];
	}
	const cl = Math.max(min, Math.min(max, value));
	return cl;
};

export const getMax = (...args) => Math.max(...args.filter(i => i != null));
export const getMin = (...args) => Math.min(...args.filter(i => i != null));

export const line = (x, {x1 = 0, y1 = 0, x2 = 1, y2 = 1, easingFn}) => {
	if (easingFn) {
		return line(easingFn(line(x, {x1, y1: 0, x2, y2: 1})), {x1: 0, y1, x2: 1, y2});
	}
	return x2 === x1 ? y1 : ((x - x1) / (x2 - x1)) * (y2 - y1) + y1;
};

export const lineLimit = (x, {x1 = 0, y1 = 0, x2 = 1, y2 = 1, easingFn}) => {
	const min = Math.min(x1, x2);
	const max = Math.max(x1, x2);
	const limitedX = Math.max(min, Math.min(max, x));
	return line(limitedX, {x1, y1, x2, y2, easingFn});
};


export function cloneRect (rect) {
	return {
		top: rect.top,
		right: rect.right,
		bottom: rect.bottom,
		left: rect.left,
		width: rect.width,
		height: rect.height,
		x: rect.x,
		y: rect.y,
	};
}

export const getViewport = () => {
	return {
		left: 0,
		right: window.innerWidth || document.documentElement.clientWidth,
		top: 0,
		bottom: window.innerHeight || document.documentElement.clientHeight,
	};
};

export const uuid = () => Array.from(crypto.getRandomValues(new Uint32Array(4))).map(n => n.toString(16)).join("-");
export const clone = (obj) => JSON.parse(JSON.stringify(obj));
export const toArray = (obj) => Object.keys(obj).map(key => ({key, value: obj[key]}));
export const each = (list, fn) => Object.keys(list).map(key => fn(list[key], key));
export const randomFloat = (min, max) => Math.random() * (max - min) + min;
export const randomInt = (min, max) => Math.floor(min + Math.random() * (max + 1 - min));
export const flattenDeep = (array, parent = []) =>
	array.reduce((parent, item) => (Array.isArray(item) ? flattenDeep(item, parent) : parent.push(item), parent), parent);


export const appearHeight = async (element, method = "show", duration = 500) => {
	const $element = $(element);
	const show = method === "show";
	$element.css({"overflow": "hidden", "transform": "scale(1)", "transition": "none", "opacity": "0", "display": ""});
	if (show) {
		$element.css({"height": "auto"});
	}
	const height = $element.height();
	$element.css({"height": `${show ? 0 : height}px`, "opacity": show ? 0 : 1});
	setTimeout(() => $element.css({"transition": `height ${duration}ms ease, opacity ${duration}ms ease`, "transition-delay": "0s, 0.2s", "height": `${show ? height : 0}px`, "opacity": show ? 1 : 0}), 50);

	return new Promise(resolve => setTimeout(() => {
		if (show) {
			$element.css({"height": "auto"});
		}
		else {
			$element.css({"display": "none"});
		}
		resolve();
	}, duration));
};

export const appearWidth = async (element, method = "show", duration = 500) => {
	const $element = $(element);
	const show = method === "show";
	$element.css({"overflow": "hidden", "transform": "scale(1)", "transition": "none", "opacity": "0", "display": ""});
	if (show) {
		$element.css({"width": "auto"});
	}
	const width = $element.width();
	$element.css({"width": `${show ? 0 : width}px`, "opacity": show ? 0 : 1});
	setTimeout(() => $element.css({"transition": `width ${duration}ms ease, opacity ${duration}ms ease`, "transition-delay": "0s, 0.2s", "width": `${show ? width : 0}px`, "opacity": show ? 1 : 0}), 50);

	return new Promise(resolve => setTimeout(() => {
		if (show) {
			$element.css({"width": "auto"});
		}
		else {
			$element.css({"display": "none"});
		}
		resolve();
	}, duration));
};

export const loadScript = (src) => {
	let script = document.querySelector(`script[src="${src}"]`);
	if (!script) {
		script = document.createElement("script");
		script.setAttribute("data-dynamic-script", "");
	}
	if (!script.hasAttribute("data-loaded")) {
		return new Promise((resolve, reject) => {
			if (!script.onload) {
				script.onload = () => script.setAttribute("data-loaded", "");
			}
			script.addEventListener("load", resolve);
			script.addEventListener("error", reject);
			script.src = src;
			document.querySelector("head").appendChild(script);
		});
	}
};

export const toBoolean = (val) => {
	if (typeof val === "string") {
		return val === "true";
	}
	return !!val;
};

export const toFormData = (obj) => {
	const result = new FormData();
	Object.keys(obj).forEach(key => result.append(key, obj[key]));
	return result;
};

export const handleResponse = (response) => {
	if (!response) {
		return;
	}
	const contentType = response.headers.get("content-type");
	if (contentType && contentType.includes("application/json")) {
		return handleJSON(response);
	}
	else if (response.status === 200) {
		return {ok: true};
	}
	return handleText(response);
};

export const handleJSON = async (response) => {
	if (!response.ok) {
		const data = await response.json();
		throw data;
	}
	return response.json();
};

export const handleText = async (response) => {
	if (!response.ok) {
		const data = await response.text();
		throw data;
	}
	return response.text();
};


export const urlParams = (source) => {
	return Object.keys(source).filter(key => source[key] != null).map((key, idx) => `${idx === 0 ? "?" : ""}${key}=${encodeURIComponent(source[key])}`).join("&");
};
export const getTrackingParams = () => {
	const url = new URL(window.location.toString());
	const urlParams = Object.fromEntries([...url.searchParams.entries()].filter(([key, value]) => {
		return key.startsWith("utm_") || ["gclid", "fbclid"].includes(key);
	}));

	if (Object.entries(urlParams).length) {
		return urlParams;
	}
	return getStoredQuery();
};
