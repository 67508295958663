export function stringToArrayBuffer (string, latin1 = false) {
	if (latin1) {
		return Uint8Array.from(string, x => x.charCodeAt(0));

	}
	return new TextEncoder().encode(string);
}

export function arrayBufferToString (arrayBuffer, latin1 = false) {
	if (latin1) {
		return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
	}
	return new TextDecoder().decode(arrayBuffer);
}

export function base64ToArrayBuffer (string) {
	if (string.length % 4 != 0) {
		string += ("===").slice(0, 4 - (string.length % 4));
	}
	const escaped = string.replace(/-/g, "+").replace(/_/g, "/");
	return stringToArrayBuffer(atob(escaped), true);
}

export function escapeBase64 (string) {
	return string.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
}

export function unescapeBase64 (string) {
	string = string.replace(/-/g, "+").replace(/_/g, "/");
	const padding = string.length % 4;
	if (padding) {
		string += ("=").repeat(4 - padding);
	}
	return string;
}

export function arrayBufferToBase64 (arrayBuffer) {
	const unescaped = arrayBufferToString(arrayBuffer, true);
	return btoa(unescaped).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
}

export function arrayBufferToBase64Payment (arrayBuffer) {
	const unescaped = arrayBufferToString(arrayBuffer, true);
	return btoa(unescaped).replace(/\+/g, "-").replace(/\//g, "_");
}

export function numberToArrayBuffer (value, size = Math.ceil(Math.log2(value + 1) / 8)) {
	const view = new DataView(new ArrayBuffer(size));
	for (let i = size - 1; i >= 0; i--) {
		view.setUint8(i, value % 256);
		value = value >> 8;
	}
	return view.buffer;
}

export function base64ToBinary (base64String) {
	const binaryString = atob(base64String);
	const bytes = new Uint8Array(binaryString.length);

	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
}

export function binaryToBase64 (buffer) {

	let binary = "";
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return btoa(binary);
}

export function stringToBase64 (jsonObj) {
	return btoaUTF8(jsonObj);
}

export function base64ToString (base64 = "") {
	const binaryString = atob(base64);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	const utf8String = new TextDecoder("utf-8").decode(bytes);
	return utf8String;
	// return atob(base64);
}

export function btoaUTF8 (str) {
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes (match, p1) {
		return String.fromCharCode("0x" + p1);
	}));
}
